import { useEffect } from "react";
import { createBrowserRouter, Navigate, type RouteObject } from "react-router-dom";
import { WebRoutes } from "consts/enpoints/web";
import { Protected, RedirectIfAuthenticated } from "ui/layouts/Auth";
import Dashboard, { DashboardIndexRedirect } from "ui/layouts/Dashboard";
import { SignInOutlet } from "ui/layouts/SignIn";
import Login from "ui/screens/Login";
import Verify2FA from "ui/screens/Verify2FA";
import SendResetPasswordLink from "ui/screens/SendResetPasswordLink";
import ChangePassword from "ui/screens/ChangePassword";
import NotAuthorized from "ui/screens/NotAuthorized";
import {
    KYCMerchantList,
    KYCMerchantSettings,
    Merchants,
    TicketingSystem,
    TicketingSystemMerchantList
} from "ui/screens/Merchants";
import Transactions from "ui/screens/Transactions";
import TransactionList from "ui/screens/TransactionList";
import UserManagement from "ui/screens/UserManagement";
import MerchantPayouts from "ui/screens/MerchantPayouts";
import { QueryBuilder, EsTransactions, EsMerchantPayoutFiles } from "ui/screens/QueryBuilder";
import { Consumers, ConsumersOutlet, ImportHistory } from "ui/screens/Consumers";
import PaymentMethodSetup from "ui/screens/Setup";
import { Filters } from "consts/transactions";
import useAuth from "features/auth/useAuth";
import StatisticDashboard from "ui/screens/StatisticDashboard";
import { UserRolesNames } from "consts/auth";

const useRouter = () => {
    const { user } = useAuth();

    const getAppRoutes = () => {
        const baseRoutes: RouteObject[] = [
            {
                path: "*",
                element: (
                    <NotAuthorized />
                )
            }
        ];

        if (!user) {
            return baseRoutes;
        }

        const userRoleName: UserRolesNames = user.roleName;

        const route = new Map<string, RouteObject>()
            .set(WebRoutes.Dashboard, {
                path: WebRoutes.Dashboard,
                element: <StatisticDashboard />
            })
            .set(WebRoutes.Transactions, {
                path: WebRoutes.Transactions,
                element: (
                    <Transactions />
                )
            })
            .set(WebRoutes.Merchants.Index, {
                path: WebRoutes.Merchants.Index,
                element: (
                    <Navigate
                        replace
                        to={WebRoutes.Merchants.List}
                    />
                )
            })
            .set(WebRoutes.Merchants.List, {
                path: WebRoutes.Merchants.List,
                element: (
                    <Merchants />
                )
            })
            .set(WebRoutes.Merchants.KYCSettings.Index, {
                path: WebRoutes.Merchants.KYCSettings.Index,
                element: (
                    <KYCMerchantList />
                ),
                children: [
                    {
                        path: `:${Filters.merchantId}`,
                        element: (
                            <KYCMerchantSettings />
                        )
                    }
                ]
            })
            .set(WebRoutes.Merchants.TicketingSystem.Index, {
                path: WebRoutes.Merchants.TicketingSystem.Index,
                element: (
                    <TicketingSystemMerchantList />
                ),
                children: [
                    {
                        path: `:${Filters.merchantId}`,
                        element: (
                            <TicketingSystem />
                        )
                    }
                ]
            })
            .set(WebRoutes.Consumers.Index, {
                path: WebRoutes.Consumers.Index,
                element: (
                    <ConsumersOutlet />
                ),
                children: [
                    {
                        index: true,
                        element: (
                            <Navigate
                                replace
                                to={WebRoutes.Consumers.List}
                            />
                        )
                    },
                    {
                        path: WebRoutes.Consumers.List,
                        element: (
                            <Consumers />
                        )
                    },
                    {
                        path: WebRoutes.Consumers.ImportHistory.Index,
                        element: (
                            <ImportHistory />
                        )
                    }
                ]
            })
            .set(WebRoutes.Users, {
                path: WebRoutes.Users,
                element: (
                    <UserManagement />
                )
            })
            .set(WebRoutes.MerchantPayouts, {
                path: WebRoutes.MerchantPayouts,
                element: (
                    <MerchantPayouts />
                )
            })
            .set(WebRoutes.QueryBuilder.Index, {
                element: (
                    <QueryBuilder />
                ),
                children: [
                    {
                        path: WebRoutes.QueryBuilder.Index,
                        element: (
                            <Navigate
                                replace
                                to={WebRoutes.QueryBuilder.EsTransactions}
                            />
                        )
                    },
                    {
                        path: WebRoutes.QueryBuilder.EsTransactions,
                        element: (
                            <EsTransactions />
                        )
                    },
                    {
                        path: WebRoutes.QueryBuilder.EsMerchantPayoutFiles,
                        element: (
                            <EsMerchantPayoutFiles />
                        )
                    }
                ]
            })
            .set(WebRoutes.Setup.Index, {
                path: WebRoutes.Merchants.Index,
                element: (
                    <Navigate
                        replace
                        to={WebRoutes.Setup.PaymentMethodSetup}
                    />
                )
            })
            .set(WebRoutes.Setup.PaymentMethodSetup, {
                path: WebRoutes.Setup.PaymentMethodSetup,
                element: (
                    <PaymentMethodSetup />
                )
            });

        const roleBasedRoutes = new Map<UserRolesNames, RouteObject[]>([
            [UserRolesNames.Employee, [
                route.get(WebRoutes.Dashboard)!,
                route.get(WebRoutes.Transactions)!,
                route.get(WebRoutes.Merchants.List)!
            ]],
            [UserRolesNames.Mors, [
                {
                    path: WebRoutes.TransactionList,
                    element: (
                        <TransactionList />
                    )
                }
            ]]
        ]);

        if (roleBasedRoutes.has(userRoleName)) {
            return [
                ...roleBasedRoutes.get(userRoleName)!,
                ...baseRoutes
            ];
        }

        return [
            ...Array.from(route.values()),
            ...baseRoutes
        ];
    };

    const routes: RouteObject[] = [
        {
            path: WebRoutes.Root,
            element: (
                <Protected>
                    <DashboardIndexRedirect>
                        <Dashboard />
                    </DashboardIndexRedirect>
                </Protected>
            ),
            children: getAppRoutes()
        },
        {
            path: WebRoutes.Login.Index,
            element: (
                <RedirectIfAuthenticated>
                    <SignInOutlet />
                </RedirectIfAuthenticated>
            ),
            children: [
                {
                    index: true,
                    element: (
                        <Login />
                    )
                },
                {
                    path: WebRoutes.Login.Verify2FA,
                    element: (
                        <Verify2FA />
                    )
                },
                {
                    path: WebRoutes.Login.SendResetLink,
                    element: (
                        <SendResetPasswordLink />
                    )
                }
            ]
        },
        {
            path: WebRoutes.ChangePassword,
            element: (
                <RedirectIfAuthenticated>
                    <SignInOutlet />
                </RedirectIfAuthenticated>
            ),
            children: [
                {
                    index: true,
                    element: (
                        <ChangePassword />
                    )
                }
            ]
        }
    ];

    useEffect(() => {
        const handleBeforeUnload = () =>
            window.history.replaceState({}, '');

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    return createBrowserRouter(routes);
};

export default useRouter;
