import { useForm as useFormHook } from "hooks";
import {
    FormField,
    type SetupPaymentMethodSpecInParamsForm,
    type SetupPaymentMethodSpecInParams
} from "./types";
import useValidationSchema from "./useValidationSchema";

export const getInitialValues = (initialValues: Partial<SetupPaymentMethodSpecInParams> = {}): SetupPaymentMethodSpecInParams => ({
    [FormField.SpecIn]: '',
    [FormField.Option]: null,
    [FormField.Values]: '',
    [FormField.Type]: '',
    [FormField.Description]: '',
    [FormField.Regex]: '',
    ...initialValues
});

export const useForm = (setupPaymentMethodSpecInParamsForm: SetupPaymentMethodSpecInParamsForm) =>
    useFormHook({
        enableReinitialize: true,
        ...setupPaymentMethodSpecInParamsForm,
        validateOnMount: true,
        validationSchema: useValidationSchema(),
        initialValues: getInitialValues(setupPaymentMethodSpecInParamsForm.initialValues)
    });
