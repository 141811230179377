import type { ID } from "types";
import { route } from "util/support";

export const enum WebRouteTypes {
    Root = 'Root',
    Dashboard = 'Dashboard',
    Login = 'Login',
    ChangePassword = 'ChangePassword',
    Transactions = 'Transactions',
    TransactionList = 'TransactionList',
    Merchants = 'Merchants',
    Users = 'Users',
    MerchantPayouts = 'MerchantPayouts',
    Consumers = 'Consumers',
    QueryBuilder = 'QueryBuilder',
    Setup = 'Setup'
};

export const WebRoutes = {
    [WebRouteTypes.Root]: route(['']),
    [WebRouteTypes.Dashboard]: route(['dashboard']),
    [WebRouteTypes.Login]: {
        Index: route(['login']),
        Verify2FA: route(['login', 'verify-2fa']),
        SendResetLink: route(['login', 'send-reset-link'])
    },
    [WebRouteTypes.ChangePassword]: route(['change-password', ':token']),
    [WebRouteTypes.Transactions]: route(['transactions']),
    [WebRouteTypes.TransactionList]: route(['transactions', 'list']),
    [WebRouteTypes.Merchants]: {
        Index: route(['merchants']),
        List: route(['merchants', 'list']),
        KYCSettings: {
            Index: route(['merchants', 'kyc-settings']),
            Merchant: (merchantId: ID) =>
                route(['merchants', 'kyc-settings', merchantId])
        },
        TicketingSystem: {
            Index: route(['merchants', 'ticketing-system']),
            Merchant: (merchantId: ID) =>
                route(['merchants', 'ticketing-system', merchantId])
        }
    },
    [WebRouteTypes.MerchantPayouts]: route(['merchants-payouts']),
    [WebRouteTypes.Consumers]: {
        Index: route(['consumers']),
        List: route(['consumers', 'list']),
        ImportHistory: {
            Index: route(['consumers', 'import-history']),
        }
    },
    [WebRouteTypes.Users]: route(['users']),
    [WebRouteTypes.QueryBuilder]: {
        Index: route(['query-builder']),
        EsTransactions: route(['query-builder', 'es-transactions']),
        EsMerchantPayoutFiles: route(['query-builder', 'es-merchant-payout-files'])
    },
    [WebRouteTypes.Setup]: {
        Index: route(['setup']),
        PaymentMethodSetup: route(['setup', 'payment-method'])
    }
};
