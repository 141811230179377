import { memo } from "react";
import type { PaymentMethod } from "features/paymentMethods/types";
import Label from "./Label";
import { Box, Tooltip } from "@mui/material";

const PaymentMethodMerchants = ({ enabledOnMerchants }: PaymentMethod) => {
  const getMerchantNames = (): string => Object.values(enabledOnMerchants).join(", ");

  return (
    <Label title="Merchant"
    >
      <Tooltip title={getMerchantNames()}>
        <Box
          sx={{
            maxWidth: '240px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer', 
          }}
        >
          {getMerchantNames()}
        </Box>
      </Tooltip>
    </Label>
  );
};

export default memo(PaymentMethodMerchants)