import { RESEND_OTP_TIMEOUT_SEC } from "consts/auth";
import { useCountdown, useCookieState } from 'hooks';
import { useSimpleRequest } from "hooks/useRequest";
import { recover } from "features/auth/api";
import type { Credentials } from "ui/forms/Auth";

export default function useSendResetLink() {
    const request = useSimpleRequest();

    const [allowResendCodeCounter, setAllowResendCodeCounter] = useCookieState({
        cookieKey: 'resendResetLinkWithin',
        defaultValue: 0
    });

    const count = useCountdown({
        timeout: Number(allowResendCodeCounter)
    });

    const sendResetLink =  async ({ email }: Credentials.Credentials) => {
        await request(() => recover({ email }), {
            onSuccess: () => setAllowResendCodeCounter(RESEND_OTP_TIMEOUT_SEC, {
                expires: 0.00069444444 // 1 minute
            }),
            notifyOnSuccess: true
        });
    };

    return {
        sendResetLink,
        count,
        disabled: count > 0
    };
};
