import { memo } from "react";
import type { PaymentMethod } from "features/paymentMethods/types";
import { Box, Grid } from "@mui/material";

const MerchantsEnable = ({ enabledOnMerchants }: PaymentMethod) => {
    const merchantNames = Object.values(enabledOnMerchants);

    return (
        <Box sx={{ maxWidth: 'fit-content' }}>
            <Grid container spacing={2}>
                {merchantNames.map((name, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        {name}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default memo(MerchantsEnable);
