import { memo } from "react";
import Box from "@mui/material/Box";
import { Checkbox } from "ui/forms/formik-elements";
import { FormField, type MerchantAccountMerchantSetupForm } from "./types";
import AddEmail from "./AddEmail";
import AccountMerchantSetupLayout from "./AccountMerchantSetupLayout";

const IpnSetup = (props: MerchantAccountMerchantSetupForm) => {
    const {
        values,
        handleBlur,
        setFieldValue,
        setFieldTouched
    } = props;

    return (
        <AccountMerchantSetupLayout
            title='Emails for IPN when already succeeded status changed'
            sx={{
                gap: 4
            }}
        >
            <Checkbox
                label='Enable email IPN notification on status change'
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                CheckboxProps={{
                    inputProps: {
                        'aria-label': 'Enable email IPN notification on status change'
                    },
                    name: FormField.EnableEmailIpnNotificationOnStatusChange,
                    checked: values[FormField.EnableEmailIpnNotificationOnStatusChange]
                }}
            />
            <AddEmail
                {...props}
                name={FormField.EmailCsvForIpnNotificationOnStatusChange}
                itemsName={FormField.EmailsCsvForIpnNotificationOnStatusChange}
            />
            <Box
                sx={{
                    '& .MuiFormControlLabel-label': {
                        fontWeight: 900
                    }
                }}
            >
                <Checkbox
                    label='Withdrawals enabled'
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    CheckboxProps={{
                        inputProps: {
                            'aria-label': 'Withdrawals enabled'
                        },
                        name: FormField.IsConsumerPayoutEnabled,
                        checked: values[FormField.IsConsumerPayoutEnabled]
                    }}
                />
            </Box>
        </AccountMerchantSetupLayout>
    );
};

export default memo(IpnSetup);
