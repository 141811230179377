import { memo } from "react";
import type { PaymentMethod } from "features/paymentMethods/types";
import Label from "./Label";
import Clipboard from "ui/molecules/Clipboard";

const PaymentMethodTag = ({ tag }: PaymentMethod) => (
    <Label
        title='Tag'
    >
        <Clipboard
            sx={{
                fontWeight: 700,
            }}
        >
            {tag}
        </Clipboard>
    </Label>
);

export default memo(PaymentMethodTag);
