import { memo } from "react";
import PaymentMethodProvider, { type PaymentMethodProviderProps } from "./PaymentMethodProvider";
import PaymentMethodAccordion from "./PaymentMethodAccordion";
import { Card } from "@mui/material";

const PaymentMethod = (props: PaymentMethodProviderProps) => (
    <PaymentMethodProvider
        {...props}
    ><Card
    sx={{
        marginBottom: 1
    }}>
        <PaymentMethodAccordion
            {...props}
        />
        </Card>
    </PaymentMethodProvider>
);

export default memo(PaymentMethod);
