export const REGEXP = {
    Phone: /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
    Password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
    Uppercase: /[A-Z]/,
    Lowercase: /[a-z]/,
    Numbers: /[0-9]/,
    SpecialChars: /[!@#$%^&*(),.?":{}|<>]/
};

export const AVATAR_SUPPORTED_FORMATS = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/gif'
];

export const AVATAR_MAX_SIZE = 3100000;
