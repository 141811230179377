import { memo } from "react";
import Box from "@mui/material/Box";
import PaymentMethodName from "./PaymentMethodName";
import PaymentMethodTag from "./PaymentMethodTag";
import PaymentMethodProvider from "./PaymentMethodProvider";
import PaymentMethodCountries from "./PaymentMethodCountries";
import PaymentMethodMerchant from "./PaymentMethodMerchant";
import PaymentMethodCurrencies from "./PaymentMethodCurrencies";
import { usePaymentMethodContext } from "../PaymentMethodProvider";

const PaymentMethodSummary = () => {
    const { getPaymentMethod } = usePaymentMethodContext();

    const paymentMethod = getPaymentMethod();

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '2fr 1fr 1fr 2fr 1fr 1fr',
                gap: 1,
                width: '100%',
                alignItems: 'center'
            }}
        >
            <PaymentMethodName
                {...paymentMethod}
            />
            <PaymentMethodTag
                {...paymentMethod}
            />
            <PaymentMethodProvider
                {...paymentMethod}
            />
            <PaymentMethodMerchant
                {...paymentMethod}
            />
            <PaymentMethodCountries
                {...paymentMethod}
            />
            <PaymentMethodCurrencies
                {...paymentMethod}
            />
        </Box>
    );
};

export default memo(PaymentMethodSummary);
