import {
    memo,
    type PropsWithChildren
} from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SpecInParams } from "ui/forms/Setup/PaymentMethod";
import type { SpecInKey } from "./types";

type Props = Omit<BoxProps, 'title'> & {
    readonly title: SpecInKey;
};

const SpecInParamsValue = ({
    sx,
    title,
    children
}: PropsWithChildren<Props>) => {
    const renderTitle = () => new Map([
        [SpecInParams.FormField.SpecIn, 'Spec-in field'],
        [SpecInParams.FormField.Option, 'Option'],
        [SpecInParams.FormField.Values, 'Values (Json assoc or empty)'],
        [SpecInParams.FormField.Type, 'Type'],
        [SpecInParams.FormField.Regex, 'Validation (Regex)'],
        [SpecInParams.FormField.Description, 'Spec-in Description']
    ]);

    return (
        <Box
            sx={{
                display: 'grid',
                gap: .5,
                ...sx
            }}
        >
            <Typography
                variant='body1'
                sx={{
                    color: 'text.secondary',
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}
            >
                {renderTitle().get(title)}
            </Typography>
            {children}
        </Box>
    );
};

export default memo(SpecInParamsValue);
