import { useFormik, type FormikConfig } from "formik";
import { FormField, type MerchantAccountMerchantSetup } from "./types";
import useValidationSchema from "./useValidationSchema";
import { BooleanSwitchValue } from "consts/general";

export const getInitialValues = (initialValues?: Partial<MerchantAccountMerchantSetup>) => ({
    [FormField.EnableEmailIpnNotificationOnStatusChange]: BooleanSwitchValue.Off,
    [FormField.EmailsCsvForIpnNotificationOnStatusChange]: Array.of<string>(),
    [FormField.EmailCsvForIpnNotificationOnStatusChange]: '',
    [FormField.TotalAmountEuroPerDay]: 1000,
    [FormField.TotalAmountEuroPerWeek]: '',
    [FormField.TotalNumberOfTransactionsPerWeek]: '',
    [FormField.MinNumberEmailsSelectedForKYCCheck]: 5,
    [FormField.EmailsCsvForCompliance]: Array.of<string>(),
    [FormField.EmailCsvForCompliance]: '',
    [FormField.BlockFakeRedirect]: BooleanSwitchValue.On,
    [FormField.IsConsumerPayoutEnabled]: BooleanSwitchValue.Off,
    ...initialValues,
});

export const useForm = (accountMerchantSetupProps: FormikConfig<MerchantAccountMerchantSetup>) =>
    useFormik<MerchantAccountMerchantSetup>({
        validateOnMount: true,
        enableReinitialize: true,
        ...accountMerchantSetupProps,
        validationSchema: useValidationSchema(),
        initialValues: getInitialValues(accountMerchantSetupProps.initialValues)
    });
