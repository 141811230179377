import type { Dispatch, SetStateAction } from "react";
import type { FormikConfig } from "formik";
import { AccountMerchantSetup } from "ui/forms/Merchant";
import useMerchantDetailsTabContext from "../../useMerchantDetailsTabContext";
import type { MerchantAccountSettingsRepository } from "../repository";
import { FormField, type MerchantAccountMerchantSetup } from "ui/forms/Merchant/AccountMerchantSetup";

export default function useMerchantAccountMerchantSetup(setLoading: Dispatch<SetStateAction<boolean>>): AccountMerchantSetup.MerchantAccountMerchantSetupForm {
    const repository = useMerchantDetailsTabContext<MerchantAccountSettingsRepository>();

    const merchantSettings = repository.merchantSettings;

    const onSubmit: FormikConfig<MerchantAccountMerchantSetup>['onSubmit'] = async (values, { setTouched }) => {
        setLoading(true);

        const {
            [FormField.EmailsCsvForIpnNotificationOnStatusChange]: ipnEmails,
            [FormField.EmailsCsvForCompliance]: complianceEmails,
            ...rest
        } = values;

        const joinEmails = (emails: string | string[]) =>
            [emails]
                .flat()
                .map(email => email.trim())
                .filter(Boolean)
                .join(',');

        return repository.updateMerchantSettings({
            ...rest,
            [FormField.EmailsCsvForIpnNotificationOnStatusChange]: joinEmails(ipnEmails),
            [FormField.EmailsCsvForCompliance]: joinEmails(complianceEmails),
            coreId: merchantSettings?.coreId,
            merchantId: merchantSettings?.merchantId
        }, { notifyOnSuccess: true })
            .finally(() => {
                setLoading(false);
                setTouched({});
            });
    };

    const getAccountMerchantSetupFormValues = () => {
        const slitEmails = (emails: string | string[]) =>
            [emails]
                .flat()
                .join('')
                .split(',')
                .map(email => email.trim())
                .filter(Boolean);

        if (!merchantSettings) {
            return AccountMerchantSetup.getInitialValues();
        }

        return AccountMerchantSetup.getInitialValues({
            [FormField.EnableEmailIpnNotificationOnStatusChange]: merchantSettings[FormField.EnableEmailIpnNotificationOnStatusChange],
            [FormField.IsConsumerPayoutEnabled]: merchantSettings[FormField.IsConsumerPayoutEnabled],
            [FormField.EmailsCsvForIpnNotificationOnStatusChange]: slitEmails(
                merchantSettings[FormField.EmailsCsvForIpnNotificationOnStatusChange]
            ),
            [FormField.TotalAmountEuroPerDay]: merchantSettings[FormField.TotalAmountEuroPerDay],
            [FormField.TotalAmountEuroPerWeek]: merchantSettings[FormField.TotalAmountEuroPerWeek],
            [FormField.TotalNumberOfTransactionsPerWeek]: merchantSettings[FormField.TotalNumberOfTransactionsPerWeek],
            [FormField.MinNumberEmailsSelectedForKYCCheck]: merchantSettings[FormField.MinNumberEmailsSelectedForKYCCheck],
            [FormField.EmailsCsvForCompliance]: slitEmails(
                merchantSettings[FormField.EmailsCsvForCompliance]
            ),
            [FormField.BlockFakeRedirect]: merchantSettings[FormField.BlockFakeRedirect]
        });
    };

    return AccountMerchantSetup.useForm({
        initialValues: getAccountMerchantSetupFormValues(),
        onSubmit
    });
};
