import { useMemo } from "react";
import { array, boolean, number, object, string } from "yup";
import { FormField } from "./types";

export default function useValidationSchema() {
    return useMemo(() =>
        object().shape({
            [FormField.EnableEmailIpnNotificationOnStatusChange]: boolean(),
            [FormField.IsConsumerPayoutEnabled]: boolean(),
            [FormField.EmailsCsvForIpnNotificationOnStatusChange]: array(),
            [FormField.EmailCsvForIpnNotificationOnStatusChange]: string()
                .email()
                .label('Email address'),
            [FormField.TotalAmountEuroPerDay]: number()
                .min(0)
                .max(1_000_000),
            [FormField.TotalAmountEuroPerWeek]: number()
                .min(0)
                .max(1_000_000),
            [FormField.TotalNumberOfTransactionsPerWeek]: number()
                .min(0)
                .max(1_000_000_000),
            [FormField.MinNumberEmailsSelectedForKYCCheck]: number()
                .min(0)
                .max(1_000_000),
            [FormField.EmailsCsvForCompliance]: array(),
            [FormField.EmailCsvForCompliance]: string()
                .email()
                .label('Email address'),
            [FormField.BlockFakeRedirect]: boolean()
        })
        , []);
};
