import { memo, type PropsWithChildren } from "react";
import type { UnsavedChangesProps } from "./types";
import useUnsavedChanges from "./useUnsavedChanges";

const UnsavedChanges = ({ children, ...restProps }: PropsWithChildren<UnsavedChangesProps>) => {
    useUnsavedChanges(restProps);

    return (
        <>
            {children}
        </>
    );
};

export default memo(UnsavedChanges);
