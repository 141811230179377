import { useMemo } from "react";
import { object, array, string } from "yup";
import { FormField } from "./types";

export default function useValidationSchema() {
    return useMemo(() =>
        object().shape({
            [FormField.Name]: string()
                .required()
                .max(264)
                .label('Method Name'),

            [FormField.Tag]: string()
                .required()
                .max(264)
                .label('Method Tag'),

            [FormField.Provider]: string()
                .required()
                .label('Provider'),

            [FormField.ForCurrencies]: array()
                .min(1)
                .label('Currency'),

            [FormField.ForCountries]: array()
                .min(1)
                .label('Country'),

            [FormField.DayToHoldOfflineFunds]: string()
                .optional()
                .label('Days To Hold Funds')
        })
        , []);
};
