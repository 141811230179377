import { memo } from "react";
import Typography from "@mui/material/Typography";
import type { SpecInParamsValueProps } from "./types";
import SpecInParamsValue from "./SpecInParamsValue";

const SpecInParamsValueScalar = ({
    specInKey,
    specInValue
}: SpecInParamsValueProps) => (
    <SpecInParamsValue
        title={specInKey}
    >
        <Typography
            variant='body2'
            sx={{
                fontWeight: 'bold',
                textAlign: 'center'
            }}
        >
            {specInValue || '-'}
        </Typography>
    </SpecInParamsValue>
);

export default memo(SpecInParamsValueScalar);
