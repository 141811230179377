import type { MerchantSettings } from "features/merchants/types";
import type { useFormik } from "formik";

export const enum FormField {
    EnableEmailIpnNotificationOnStatusChange = 'sendEmailIpnWhenAlreadySucceededStatusChanged',
    EmailsCsvForIpnNotificationOnStatusChange = 'emailsCsvForIpnWhenAlreadySucceededStatusChanged',
    EmailCsvForIpnNotificationOnStatusChange = 'EmailCsvForIpnNotificationOnStatusChange',
    TotalAmountEuroPerDay = 'weeklyComplianceMinAmountEurPerDay',
    TotalAmountEuroPerWeek = 'weeklyComplianceMinAmountEurPerWeek',
    TotalNumberOfTransactionsPerWeek = 'weeklyComplianceMinNumberOfTrPerWeek',
    MinNumberEmailsSelectedForKYCCheck = 'weeklyComplianceNumberOfEmails',
    EmailsCsvForCompliance = 'emailsCsvForCompliance',
    EmailCsvForCompliance = 'EmailCsvForCompliance',
    BlockFakeRedirect = 'blockFakeRedirect',
    IsConsumerPayoutEnabled = 'isConsumerPayoutEnabled'
};

export type MerchantAccountIpnSetup = Pick<
    MerchantSettings,
    | 'emailsCsvForIpnWhenAlreadySucceededStatusChanged'
    | 'sendEmailIpnWhenAlreadySucceededStatusChanged'
    | 'isConsumerPayoutEnabled'
> & {
    readonly emailCsvForIpnNotificationOnStatusChange?: string;
};

export type MerchantAccountComplienceSetup = Pick<
    MerchantSettings,
    | 'weeklyComplianceMinAmountEurPerDay'
    | 'weeklyComplianceMinAmountEurPerWeek'
    | 'weeklyComplianceMinNumberOfTrPerWeek'
    | 'weeklyComplianceNumberOfEmails'
    | 'emailsCsvForCompliance'
> & {
    readonly emailCsvForCompliance?: string;
};

export type MerchantAccountBlockFakeRedirect = Pick<
    MerchantSettings,
    | 'blockFakeRedirect'
>;

export type MerchantAccountMerchantSetup =
    & MerchantAccountIpnSetup
    & MerchantAccountComplienceSetup
    & MerchantAccountBlockFakeRedirect;

export type MerchantAccountMerchantSetupForm = ReturnType<typeof useFormik<
    MerchantAccountMerchantSetup
>>;
