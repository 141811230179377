import type { PayloadAction } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";
import type { WritableDraft } from "immer/dist/internal";
import type {
    BitsoBanksResponse,
    ConstantsResponse,
    CountriesPaginatedResponse,
    DomainsResourceResponse,
    GeneralState,
    UserPreferencesResponse
} from "./types";
import { booleanFilterFactory } from "util/filters";
import { Option } from "util/option";
import type { ThunkReturnType } from "types";
import { convertArrayToObject, convertToOptions } from "util/transformers";
import { capitalizeFirstLetterFormatter } from "util/formaters";
import { KYCProcessingStatus, KYCStatus } from "consts/kyc";
import { MULTI_COLUMN_SEPARATOR } from "consts/filters";
import { SamePersonIdentifierSource } from "consts/consumers";
import { OpenBankingResultStatus } from "consts/transactions";
import { parse } from "util/parsers";
import { AccountWithdrawalOption } from "consts/merchants";
import { fromPaginateResourceResponse } from "util/api";

export const constantsRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.constantsLoadingState = ApiState.Pending;
};

export const constantsRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<ConstantsResponse>>
) => {
    state.constantsLoadingState = ApiState.Succeeded;
    state.constants = {
        ...state.constants,
        ...payload!.data,
        TransactionPayoutStatus: booleanFilterFactory(['Not Paid', 'Paid']),
        TransactionSettlementStatus: booleanFilterFactory(['Not Settled', 'Settled']),
        Via: convertToOptions(
            payload!.data.TransactionResultMessage ?? {},
            capitalizeFirstLetterFormatter
        ),
        TransactionCurrencies: Array.from(new Set<string>(payload!.data.TransactionCurrencies ?? [])),
        MerchantPayoutIncrementTaxName: Object.values(payload!.data.MerchantPayoutIncrementTaxName ?? {}),
        MerchantPayoutIncrementTaxRefColumnsFrom: Object.values(payload!.data.MerchantPayoutIncrementTaxRefColumnsFrom ?? {}),
        MerchantPayoutFileTaxName: Object.values(payload!.data.MerchantPayoutFileTaxName ?? {}),
        KYCStatusEnums: convertToOptions(
            `${
                KYCStatus.Pending}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.Success}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.Failed}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.NA}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.ManuallyCompleted}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.ManuallyPending}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.Triggered}`
                .split(MULTI_COLUMN_SEPARATOR)
        ),
        KYCStatusesMap: [
            Option.make(KYCProcessingStatus.NA, 'N/A'),
            ...convertToOptions(payload!.data.KycStatusesMap ?? {})
        ],
        CardIssuer: convertToOptions(
            payload!.data.TransactionCardIssuers ?? {},
            capitalizeFirstLetterFormatter
        ),
        MerchantMethodPivotActiveDomainOption: convertToOptions(
            payload!.data.MerchantMethodPivotActiveDomain ?? {},
            capitalizeFirstLetterFormatter
        ),
        MerchantGroupNames: convertToOptions(
            payload!.data.MerchantGroupName ?? {},
            (_, value) => value!
        ),
        SamePersonIdentifierSourceTypes: [
            Option.make('', SamePersonIdentifierSource.Original),
            Option.make(SamePersonIdentifierSource.Iban),
            Option.make(SamePersonIdentifierSource.Manual),
            Option.make(SamePersonIdentifierSource.KYC)
        ],
        AccountWithdrawalOptions: [
            Option.make(AccountWithdrawalOption.Automatic, 'Automatic'),
            Option.make(AccountWithdrawalOption.Manual, 'Manual')
        ],
        PayoutCycles: convertToOptions(
            payload!.data.PayoutCycle ?? {},
            (_, value) => capitalizeFirstLetterFormatter(value!)
        ),
        ObResultStatuses: [
            {
                id: OpenBankingResultStatus.BankInfoNotSubmitted,
                name: 'Bank info not submitted'
            },
            {
                id: OpenBankingResultStatus.BankInfoSubmitted,
                name: 'Bank info submitted'
            },
            {
                id: OpenBankingResultStatus.PaymentFinalizedWaitingConfirmation,
                name: 'Payment finalized. Waiting confirmation.'
            },
            {
                id: OpenBankingResultStatus.RedirectedConsumerToAuthorizePayment,
                name: 'Redirected the consumer to authorize the payment'
            },
            {
                id: OpenBankingResultStatus.RequestedConsumerToAuthorizePayment,
                name: 'Requested the consumer to authorise the payment on its bank app/page via QR or token'
            },
            // {
            //     id: OpenBankingResultStatus.RequestedPasswordForConsumer,
            //     name: 'Requested password from the consumer'
            // },
            // {
            //     id: OpenBankingResultStatus.RequestedMoreInfoFromConsumer,
            //     name: 'Requested more info (answer) from the consumer'
            // },
            // {
            //     id: OpenBankingResultStatus.RequestedOptionFromConsumer,
            //     name: 'Requested an option from the consumer'
            // },
            //  {
            //     id: OpenBankingResultStatus.ErrorHasOccurred,
            //     name: 'An error has occurred'
            // },
            {
                id: OpenBankingResultStatus.RetryToFinalizePaymentAfterPeriod,
                name: 'Retry to finalize payment after a period.'
            },
            {
                id: OpenBankingResultStatus.AccountInitFinalizedWaitingPaymentInit,
                name: 'Account init finalized. Waiting payment init'
            },
            {
                id: OpenBankingResultStatus.RedirectedConsumerForAccountConfirmation,
                name: 'Redirected the consumer for account confirmation'
            },
            {
                id: OpenBankingResultStatus.RequestedConsumerToAuthorizeAccount,
                name: 'Requested the consumer to authorise the account on its bank app/page via QR or token'
            },
            {
                id: OpenBankingResultStatus.RetryToFinalizeAccountAfterPeriod,
                name: 'Retry to finalize account after a period.'
            }
        ],
        TransactionDirections: convertToOptions(payload!.data.TransactionDirection ?? {})
    };
};

export const constantsRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.constantsLoadingState = ApiState.Failed;
};

export const bitsoBanksRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.bitsoBanksLoadingState = ApiState.Pending;
};

export const bitsoBanksRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<BitsoBanksResponse>>
) => {
    state.bitsoBanksLoadingState = ApiState.Succeeded;
    state.bitsoBanks = payload!.data;
};

export const bitsoBanksRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.bitsoBanksLoadingState = ApiState.Failed;
    state.bitsoBanks = null;
};

export const domainsRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.domainsLoadingState = ApiState.Pending;
};

export const domainsRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<DomainsResourceResponse>>) => {
    state.domainsLoadingState = ApiState.Succeeded;

    const domains = payload!.data;

    state.domains = domains && {
        ...domains,
        setupValue: convertArrayToObject(domains.setupValue.split(','))
    };
};

export const domainsRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.domainsLoadingState = ApiState.Pending;
    state.domains = null;
};

export const countriesRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.countriesLoadingState = ApiState.Pending;
};

export const countriesRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<CountriesPaginatedResponse>>) => {
    state.countriesLoadingState = ApiState.Succeeded;
    state.countries = fromPaginateResourceResponse(payload!);
};

export const countriesRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.countriesLoadingState = ApiState.Pending;
    state.countries = [];
};

export const userPreferencesRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.userPreferencesLoadingState = ApiState.Pending;
};

export const userPreferencesRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<UserPreferencesResponse>>
) => {
    const { data } = payload!;

    state.userPreferencesLoadingState = ApiState.Succeeded;
    state.userPreferences = {
        ...(data ?? {}),
        preferences: parse(data.preferences, {})
    };
};

export const userPreferencesRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.userPreferencesLoadingState = ApiState.Failed;
    state.userPreferences = {
        preferences: {}
    };
};
